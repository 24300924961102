import * as React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import {Helmet} from 'react-helmet'

import siteSettings from "../constants/site-settings"

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import ContactFormComponent from "../components/contact-form";
import CarouselComponent from "../components/carousel";

import ProfessionalServiceSvg from "../images/icons/professional-service.svg"
import CompetitivePricesSvg from "../images/icons/competitive-prices.svg"
import HighestQualitySvg from "../images/icons/highest-quality.svg"
import OverheadInstallationsSvg from "../images/icons/overhead-installations.svg"
import EmailSvg from "../images/icons/email-dark.svg"
import PhoneDarkSvg from "../images/icons/phone-dark.svg"
import MapPinSvg from "../images/icons/map-pin.svg"
import ClockSvg from "../images/icons/clock-dark.svg";
import BackgroundSlider from "gatsby-image-background-slider"
import styled from "styled-components"
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import '../styles/index.scss'

const IndexPage = ({data}) => {
  const fluidTopImgSources = [
    data.xlTop.childImageSharp.fluid,
    {
      ...data.mdTop.childImageSharp.fluid,
      media: `(max-width: 1279px)`,
    },
  ];

  const fluidGDBRepairLogoImgSource = [
    data.lgGDBRepairLogo.childImageSharp.fluid,
  ];

  const fluidLiftMasterImgSource = [
    data.liftMaster.childImageSharp.fluid,
  ];

  const fluidCraftsmanImgSource = [
    data.craftsman.childImageSharp.fluid,
  ];

  const fluidGenieImgSource = [
    data.genie.childImageSharp.fluid,
  ];

  const fluidChamberlainImgSource = [
    data.chamberlain.childImageSharp.fluid,
  ];

  const fluidChiImgSource = [
    data.chi.childImageSharp.fluid,
  ];

  const fluidClopayImgSource = [
    data.clopay.childImageSharp.fluid,
  ];

  const fluidRaynorImgSource = [
    data.raynor.childImageSharp.fluid,
  ];

  const fluidWayneDaltonImgSource = [
    data.wayneDalton.childImageSharp.fluid,
  ];

  const fluidStanleyImgSource = [
    data.stanley.childImageSharp.fluid,
  ];

  const fluid5starImgSource = [
    data.star5.childImageSharp.fluid,
  ];

  const fluidLicensedImgSource = [
    data.licensed.childImageSharp.fluid,
  ];

  const fluidUkraineImgSource = [
    data.ukraine.childImageSharp.fluid,
  ];

  const fluidBgImgSource = data;

  const emergency1ImgSource = [
    data.emergency1.childImageSharp.fluid,
  ];

  const emergency2ImgSource = [
    data.emergency2.childImageSharp.fluid,
  ];

  const emergency3ImgSource = [
    data.emergency3.childImageSharp.fluid,
  ];

  const emergency4ImgSource = [
    data.emergency4.childImageSharp.fluid,
  ];

  const paymentImgSource = [
    data.payment.childImageSharp.fluid,
  ];
  
  const metaTitle = `${siteSettings.siteTitle} - Commercial and Residential Service in Chicagoland`;
  const metaDescription = `Professional Garage Door Service | Overhead door Installation, Repair, Maintenance | 24-hr emergency service ${siteSettings.phoneNumberPretty}`;
//   const keyframesFixed = `
//   @keyframes changeImage {
//     0% {
//       background-image: url("${emergency1ImgSource}");
      
//     }
  
//     50% {
//       background-image: url("${emergency2ImgSource}");
      
//     }

//     100% {
//       background-image: url("${emergency1ImgSource}");
      
//     }
//   }
// `;
// styleSheet.insertRule(keyframesFixed, styleSheet.cssRules.length);
  const params = {
    // breakpoints: {
    //   768: {
    //     pagination: {
    //       el: '.swiper-pagination',
    //       type: 'bullets',
    //       clickable: true
    //     }
    //   }
    // },
    
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,
    centeredSlides: true,
    loop: true
  };

  return (
    <div className="w-full">
      <Helmet>
        <title>{metaTitle}</title>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover"/>
        <meta name="description" content={metaDescription}/>
        <meta name="keywords" content="garage door repair, garage door springs, garage door installation, overhead garage door, local garage door, garage door"/>

        <meta http-equiv="refresh" content="0;url=https://www.gdbgaragedoorservice.com/"/>
        
        <meta property="og:site_name" content={siteSettings.siteTitle}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={metaTitle}/>
        <meta property="og:description" content={siteSettings.siteDescription}/>
        <meta property="og:url" content={siteSettings.siteUrl}/>
        <meta property="og:type" content="Landing page"/>

        <meta property="og:image" content={siteSettings.image}/>
        <meta property="og:image:width" content={siteSettings.shareImageWidth}/>
        <meta property="og:image:height" content={siteSettings.shareImageHeight}/>

        <meta name="twitter:title" content={siteSettings.siteTitle}/>
        <meta name="twitter:description" content={siteSettings.siteDescription}/>
        <meta name="twitter:url" content={siteSettings.siteUrl}/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:image" content={siteSettings.image}/>

        <meta name="google-site-verification" content="_15yVB2JdnGYJioiAfQOr1Ic_rwEdE34nZIg8EKsCTk" />

        <link rel="canonical" href={siteSettings.siteUrl}/>
        
        <script defer data-domain="gdb-repair.com" src="https://plausible.io/js/plausible.js"/>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "GDB Garage Door",
            "image": "https://www.gdb-repair.com/main.webp",
            "@id": "",
            "url": "https://www.gdb-repair.com/",
            "telephone": "773-956-1198",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1043 S York Rd Ste",
              "addressLocality": "Bensenville",
              "addressRegion": "IL",
              "postalCode": "60106",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 41.933984,
              "longitude": -87.93830559999999
            },
            "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Mon",
                "Tue",
                "Wed",
                "Thu",
                "Fri"
              ],
              "opens": "24/7",
            
            },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Saturday",
                "Sunday"
              ],
              "opens": "07:00",
              "closes": "20:00"
            }],
            "sameAs": [
              "https://www.facebook.com/GDB.GarageDoorRepair",
              "https://www.instagram.com/gdbgaragedoor/",
              "https://twitter.com/DoorGdb",
              "https://www.youtube.com/channel/UCK8N5Pkvm2G_RarsfRfPjYQ",
              "https://www.gdb-repair.com/"
            ] 
          })}
        </script>
      </Helmet>

      <HeaderComponent/>
      <main className="main flex flex-col relative w-full">
        <div className="top-row">
          {/* <div className="ukraine-flag-blue"></div>
          <div className="ukraine-flag-yellow"></div> */}
          <div className="block lg:hidden slider-block">
            <BackgroundSlider
              query={fluidBgImgSource}
              initDelay={2}
              transition={4}
              duration={5}
              images={["b1.jpg", "b2.jpg", "b3.jpg", "top-md.webp"]}
              style={{
                zIndex: 1,
                height: 'auto',
                top: '-30px'
              }}
            />
          </div>
          {/* <Img fluid={fluidTopImgSources} objectFit="cover" className="block lg:hidden" alt="House"/> */}
          <div className="container mx-auto flex flex-col lg:flex-row px-5">
            <div className="flex order-1 lg:order-2 flex-grow-1 mb-5 lg:mb-0 self-center">
              <div className="top-img w-full h-full hidden lg:block">
                {/* <Img fluid={fluidTopImgSources} objectFit="cover" alt="House"/> */}
                {/* <div> */}
                  <BackgroundSlider
                    query={fluidBgImgSource}
                    initDelay={2}
                    transition={4}
                    duration={5}
                    images={["b1.jpg", "b2.jpg", "b3.jpg", "top-md.webp"]}
                    style={{
                      zIndex: 1
                    }}
                  />
                {/* </div> */}
                
              </div>
              
            </div>
            <div className="flex flex-col order-2 lg:order-1 justify-center lg:pr-10">
              <h1 className="mb-4 lg:mt-10 xl:mt-0">GDB Garage Door</h1>
              <p className="mb-8">GDB Garage Door is a family owned and operated business providing garage door services for the Chicagoland community. Commercial and residential garage doors are what we specialize in. 24 hour same day emergency service is available for any future needs. GDB Garage Door provides services such as installing new garage doors and garage door openers as well as repair and maintenance for any size job. You can feel confident that GDB Garage Door can service all your garage door needs. Call us for a free quote today!!.</p>
              <a className="gdb-btn primary flex self-center lg:self-start mb-10 xl:mb-0" href={siteSettings.phoneNumberLink}>{siteSettings.phoneNumberButtonText}</a>
            </div>
          </div>
        </div>
        <div className="love-us-row">
          <div className="container mx-auto px-5 py-10">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-y-10 sm:gap-10">
              <div className="flex flex-col items-center px-10 xl:px-0 md:order-2 lg:order-1">
                <ProfessionalServiceSvg className="mb-2"/>
                <h3 className="mb-2 text-center">Professional service</h3>
                <p className="text-center"></p>
              </div>
              <div className="flex flex-col items-center px-10 xl:px-0 md:order-2 lg:order-2">
                <CompetitivePricesSvg className="mb-2"/>
                <h3 className="mb-2 text-center">Competitive prices</h3>
                <p className="text-center"></p>
              </div>
              <div className="flex flex-col items-center px-10 xl:px-0 md:order-1 md:col-span-2 lg:order-3 lg:col-span-1">
                <Img fluid={fluidUkraineImgSource} objectFit="cover" className="ukraine" alt="Stand With UKRAINE"/>
              </div>
              <div className="flex flex-col items-center px-10 xl:px-0 md:order-2 lg:order-4">
                <HighestQualitySvg className="mb-2"/>
                <h3 className="mb-2 text-center">Highest work quality</h3>
                <p className="text-center"></p>
              </div>
              <div className="flex flex-col items-center px-10 xl:px-0 md:order-2 lg:order-5">
                <OverheadInstallationsSvg className="mb-2"/>
                <h3 className="mb-2 text-center">Overhead Garage Door Installation & Sales</h3>
                <p className="text-center"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="emergency-row">
          <div className="container mx-auto px-5 py-10">
            <div className="flex flex-col gap-5 lg:flex-row">
              <div className="flex flex-col flex-1">
                <h2 className="mb-3 md:text-center lg:text-left">Do you have a problem that needs immediate attention? </h2>
                <p className="md:text-center lg:text-left">Don’t hesitate to give our team a call 24 hours a day to handle all your garage door needs.</p>
                <a className="gdb-btn primary flex md:self-center lg:self-start mt-5" href={siteSettings.phoneNumberLink}>Call us</a>
              </div>
              <p className="flex w-full lg:w-1/3 items-center md:text-center lg:text-left text-sm">
                <div className='css-slides'>
                  <Img style={{position: 'absolute'}} loading="lazy" fluid={emergency1ImgSource} objectFit="cover" alt="em1"/>
                  <Img style={{position: 'absolute'}} loading="lazy" fluid={emergency2ImgSource} objectFit="cover" alt="em2"/>
                  <Img style={{position: 'absolute'}} loading="lazy" fluid={emergency3ImgSource} objectFit="cover" alt="em3"/>
                  <Img style={{position: 'absolute'}} loading="lazy" fluid={emergency4ImgSource} objectFit="cover" alt="em4"/>
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="comments-row">
          <div className="container mx-auto px-5 py-10">
            <div className="flex flex-col gap-10">
              <div className="flex flex-row gap-5 items-center justify-center">
                <Img fluid={fluid5starImgSource} objectFit="cover" className="stars hidden lg:block" alt="Google Business Review"/>
                <h2 className="text-center">What our customers are saying: </h2>
              </div>
              <CarouselComponent />
              <a className="gdb-btn dark flex self-center" target="_blank" rel="noopener noreferrer" href={siteSettings.googleCommentsLink}>View all reviews</a>
            </div>
          </div>
        </div>
        <div className="info-row">
          <div className="container mx-auto px-5 py-10">
            <div className="flex flex-col md:flex-row justify-center">
              <div className="flex flex-col md:w-1/2">
                <h2 className="md:text-center lg:text-left">LICENSED. BONDED & INSURED.</h2>
                <div className="flex flex-col">
                  <p className="mb-5">Our experienced and professional team will always be on time to provide you with the best service to meet and exceed your expectations.
                    Always know that you can count on us 24 hours a day for any emergency situation that arises.</p>
                  <h4 className="phone-number">{siteSettings.phoneNumberPretty}</h4>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="order-4 flex flex-col md:col-span-2 md:items-center lg:col-span-1 lg:items-start">
                  <h4 className="mb-3">Business Hours:</h4>
                  <ul className="text-md mb-5 pl-5 list-disc text-white">
                    <li><div className="flex place-content-between"><span>Mon</span> <span>7:30am-08:00pm</span></div></li>
                    <li><div className="flex place-content-between"><span>Tue</span> <span>7:30am-08:00pm</span></div></li>
                    <li><div className="flex place-content-between"><span>Wed</span> <span>7:30am-08:00pm</span></div></li>
                    <li><div className="flex place-content-between"><span>Thu</span> <span>7:30am-08:00pm</span></div></li>
                    <li><div className="flex place-content-between"><span>Fri</span> <span>7:30am-08:00pm</span></div></li>
                    <li><div className="flex place-content-between gap-2"><span>Sat</span> <span> 7:30am-03:30pm</span></div></li>
                    <li><div className="flex place-content-between gap-2"><span>Sun</span> <span> CLOSED</span></div></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <h4 className="red text-center">24 hours a day for any emergency situation</h4>
              <a className="gdb-btn primary flex text-center" href={siteSettings.phoneNumberLink}>Call the specialist</a>
            </div>
          </div>
        </div>
        <div className="products-row">
          <div className="container mx-auto px-5 py-10">
            <h2 className="text-center mb-10">WE WORK WITH THESE BRANDS.:</h2>
            <Swiper {...params} style={{width: '100%'}}>
              <Img fluid={fluidLiftMasterImgSource} objectFit="cover" className="image" alt="Life Master"/>
              <Img fluid={fluidCraftsmanImgSource} objectFit="cover" className="image" alt="Craftsman"/>
              <Img fluid={fluidGenieImgSource} objectFit="cover" className="image" alt="GENIE"/>
              <Img fluid={fluidChamberlainImgSource} objectFit="cover" className="image" alt="CHAMBERLAIN"/>
              <Img fluid={fluidChiImgSource} objectFit="cover" className="image" alt="CHI Overhead Doors"/>
              <Img fluid={fluidClopayImgSource} objectFit="cover" className="image" alt="Clopay"/>
              <Img fluid={fluidRaynorImgSource} objectFit="cover" className="image" alt="Raynor"/>
              <Img fluid={fluidWayneDaltonImgSource} objectFit="cover" className="image" alt="Wayne Dalton Garage Doors"/>
              <Img fluid={fluidStanleyImgSource} objectFit="cover" className="image" alt="Stanley"/>
            </Swiper>
          </div>
        </div>
        <div className="contact-us-row">
          <div className="container mx-auto px-5 py-10">
            <div className="flex flex-row lg:justify-center">
              <div className="flex flex-col lg:flex-row gap-10">
                <div>
                  <h3>CONTACT INFO</h3>
                  <p className="mb-5">GDB Garage Door</p>
                  <div className="mb-5">
                    <h4 className="flex"><MapPinSvg className="mr-2"/>Main Headquarters</h4>
                    <p className="mb-5">1043 S York Rd Ste. LL5A<br/>
                      Bensenville, IL 60106</p>
                  </div>
                  <div className="mb-5">
                    <h4 className="flex"><PhoneDarkSvg className="mr-2"/>Phone</h4>
                    <p className="font-semibold">{siteSettings.phoneNumberPretty}</p>
                    <p className="font-semibold"></p>
                  </div>
                  <div>
                    <h4 className="flex"><EmailSvg className="mr-2"/>Email</h4>
                    <p className="mb-5">{siteSettings.email}</p>
                  </div>
                </div>
                <div>
                  <h4 className="flex mb-1"><ClockSvg className="mr-2"/>Business Hours:</h4>
                  <ul className="text-lg mb-5 pl-5 list-disc work-time-block">
                  <li><div className="flex place-content-between"><span>Mon</span> <span>7:30am-08:00pm</span></div></li>
                  <li><div className="flex place-content-between"><span>Tue</span> <span>7:30am-08:00pm</span></div></li>
                  <li><div className="flex place-content-between"><span>Wed</span> <span>7:30am-08:00pm</span></div></li>
                  <li><div className="flex place-content-between"><span>Thu</span> <span>7:30am-08:00pm</span></div></li>
                  <li><div className="flex place-content-between"><span>Fri</span> <span>7:30am-08:00pm</span></div></li>
                  <li><div className="flex place-content-between gap-2"><span>Sat</span> <span> 7:30am-03:30pm</span></div></li>
                  <li><div className="flex place-content-between gap-2"><span>Sun</span> <span> CLOSED</span></div></li>
                  </ul>
                  <h3 className="red">24 hours a day for any emergency situation</h3>
                </div>
                <div className="text-center">
                  <h4 style={{paddingBottom: '10px'}}>Various Payment Options</h4>
                  <div><Img className="text-center" fluid={paymentImgSource} alt="payment"/></div>
                  <div className="py-5">Local Garage Door Experts | Bensenville</div>
                  <div>We provide garage door repair and installation services across the vast majority Suburbs of DuPage County.</div>
                  <div className="py-5">Need Garage Door Service in Bensenville?</div>
                  <div className="flex justify-center">
                    <a className="gdb-btn primary flex md:self-center lg:self-start mt-5 justify-center" href={siteSettings.phoneNumberLink}>Call us</a>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterComponent/>
    </div>
  )
}

export default IndexPage

export const query = graphql`
    query {
        xlTop: file(relativePath: { eq: "top-xl.webp" }) {
            childImageSharp {
                fluid(maxWidth: 705, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        mdTop: file(relativePath: { eq: "top-md.webp" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lgCollage1: file(relativePath: { eq: "collage-1-lg.webp" }) {
            childImageSharp {
                fluid(maxWidth: 630, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lgCollage2: file(relativePath: { eq: "collage-2-lg.webp" }) {
            childImageSharp {
                fluid(maxWidth: 630, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lgCollage3: file(relativePath: { eq: "collage-3-lg.webp" }) {
            childImageSharp {
                fluid(maxWidth: 630, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lgGDBRepairLogo: file(relativePath: { eq: "gdb-repair.webp" }) {
            childImageSharp {
                fluid(maxWidth: 630, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        liftMaster: file(relativePath: { eq: "liftmaster.webp" }) {
            childImageSharp {
                fluid(maxWidth: 190, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        craftsman: file(relativePath: { eq: "craftsman.webp" }) {
            childImageSharp {
                fluid(maxWidth: 190, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        genie: file(relativePath: { eq: "genie.webp" }) {
            childImageSharp {
                fluid(maxWidth: 190, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        chamberlain: file(relativePath: { eq: "chamberlain.webp" }) {
            childImageSharp {
                fluid(maxWidth: 190, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        chi: file(relativePath: { eq: "chi.webp" }) {
            childImageSharp {
                fluid(maxWidth: 190, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        clopay: file(relativePath: { eq: "clopay.webp" }) {
            childImageSharp {
                fluid(maxWidth: 190, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        raynor: file(relativePath: { eq: "raynor.webp" }) {
            childImageSharp {
                fluid(maxWidth: 190, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wayneDalton: file(relativePath: { eq: "wayne-dalton.webp" }) {
            childImageSharp {
                fluid(maxWidth: 190, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        stanley: file(relativePath: { eq: "stanley.webp" }) {
            childImageSharp {
                fluid(maxWidth: 190, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        star5: file(relativePath: { eq: "5star.webp" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        licensed: file(relativePath: { eq: "licensed_insured_bonded.webp" }) {
            childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ukraine: file(relativePath: { eq: "ukraine.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        backgrounds: allFile (filter: {sourceInstanceName: {eq: "images"}}){
            nodes {
              relativePath
              childImageSharp {
                fluid (maxWidth: 1400, quality: 100){
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        emergency1: file(relativePath: { eq: "emergency-1.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 610, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        emergency2: file(relativePath: { eq: "emergency-2.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 610, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        emergency3: file(relativePath: { eq: "emergency-3.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 610, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        emergency4: file(relativePath: { eq: "emergency-4.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 610, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        payment: file(relativePath: { eq: "payment.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
