import React, {useState} from "react"

import StarSvg from "../images/icons/star.svg";
import ArrowRightDarkSvg from "../images/icons/arrow-right-dark.svg";
import ArrowSvg from "../images/icons/arrow.svg";

import './carousel.scss'

function CarouselComponent() {

  const comments = [
    {
      author: "Martha Hoosier",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a/AATXAJy5djYxdT7aMp5PVcWbDPCNZm43RAoo4WkrI5LX=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "Quick, friendly and efficient service! Very knowledgeable and problem accurately diagnosed and repaired within 30 minutes. Highly recommend!",
      date: "September 2021",
      link: "https://goo.gl/maps/SNMXB2Zse2J9e7BZ9"
    },
    {
      author: "Hector Cruz",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a/AATXAJxWU4WZctlZ6TwQcacjwn0UjlTtE8Hb-RpfTTzU=w36-h36-p-c0x00000000-rp-mo-ba4-br100",
      content: "Very professional very polite new exactly what he had to do after he assessed a problem I was very pleased I will also recommend them to all my friends and family",
      date: "September 2021",
      link: "https://goo.gl/maps/LzawFCe1Yu9RDuC97"
    },
    {
      author: "Katelyn Rechenmacher",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a-/AOh14GhlCkdtCriBNrKD0pFl3Tptw_0Xq136lV3iaEdMmw=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "Very friendly, knowledgeable, and helpful. They were able to come out and look at my garage the same day I called them.",
      date: "September 2021",
      link: "https://goo.gl/maps/ehemmde7U5DXkx777"
    },
    {
      author: "Greg Hawkins",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a-/AOh14Ghb1kSIfttk09PoFDi-zSh11pttexWtA-5ZjoyE=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "Great Service! Awesome Experience! Extremely Knowledgeable! Would Highly Recommend!",
      date: "July 2021",
      link: "https://goo.gl/maps/fggXtSP466gWrqKp8"
    },
    {
      author: "Alexander Wilke",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a-/AOh14Gi-qU1z_qkGXiW7XVMP-7pTEr8XHuiRkxJJ4Gw5=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "High-quality and speedy service!",
      date: "September 2021",
      link: "https://goo.gl/maps/LJqivtzprQkFAFN2A"
    },
    {
      author: "Amber Jonsson",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a/AATXAJyZ8QHlOeuDMQXvK2WG5IflaL3ZTMMOZSy67Mbx=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "Nick was absolutely amazing! He replaced my garage door torsion spring so my garage door would open and close again! He was quick, friendly and informative! Will definitely use this company again! Thanks Nick for the great service!!!",
      date: "September 2021",
      link: "https://goo.gl/maps/QERHXM9KGTCBJY3j6"
    },
    {
      author: "Aleksandr Soldatskyy",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a/AATXAJxu1TozX2AOC7OUXx9XrtgoaITY08WNw5FIn6XqGQ=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "Great service and very fast arrived. Knows his business. The company not only fixed my problem with the on/off gate they are serviced the gear gate. Will definitely call them again in the future. Great work! Thank you.",
      date: "September 2021",
      link: "https://goo.gl/maps/9vHk7Z99KdhyZtAH9"
    },
    {
      author: "AnnaMarie Valencia",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a/AATXAJwBCwdhBeRzMcvTkd0O0spdHjfcP8HWCf5OLl60=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "I chose this company based on the reviews and I think that they were all spot on. I gave Nick a call and he found an opening same day and gave me a time slot between 3-5. He called me 30 mins before he arrived and was promptly there around 3! He was very knowledgeable about everything and made sure to express to us what he was looking at and would be fixing for us. He installed new sensors for us and helped us set up our new pad and openers that we had purchased prior to his arrival. The pad we used was not one he normally worked with but he made sure that he learned how to use it and was able to set it up no problem! We have had no problems since and I would recommend this company to anyone I met! They are reasonably priced as well!",
      date: "September 2021",
      link: "https://goo.gl/maps/jbeXNajDZJsfHzZP8"
    },
    {
      author: "Patty Judge",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a/AATXAJy-iCAmpwkgZDsuVNfOmgnzIOhST5DkoHrMgQEq=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "I was very happy with the work that was done on the garage door. And he was a very nice young man. He showed up during the time that he said he would. Would definitely contact him again when I need more repairs",
      date: "February 2021",
      link: "https://goo.gl/maps/BAuYJJna5joSNQWe9"
    },
    {
      author: "Andrew Bilyi",
      stars: 5,
      image: "https://lh3.googleusercontent.com/ogw/ADea4I7Y-C4ozyxrLCS5ovW1oSETQQ223pzAL9EgcQPnVA=s32-c-mo",
      content: "This site was built by me. The man who provides you garage door repair services and owns this resource is my brother. The Bilyis family is known for the quality of the provided services :)",
      date: "June 2021",
    },
    {
      author: "Luz Corra",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a/AATXAJyR-gDJnD6DTRuIVUUpBinxO8W35iPtrSJ1g9n8=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "Great service, 100% satisfied. Had our garage door fixes within 30 minutes. Will definitely call them again in the future.",
      date: "February 2021",
      link: "https://goo.gl/maps/BAuYJJna5joSNQWe9"
    },
    {
      author: "Sudip Patel",
      stars: 5,
      image: "https://lh3.googleusercontent.com/a-/AOh14Ghs5jR_GQy_ul-zr0smixvmkcA21qv5kjEWqpvBsA=w36-h36-p-c0x00000000-rp-mo-br100",
      content: "Excellent…Amazing work & very knowledgeable…Nick is very nice person (friendly & Professional)…he always provides genuine guidance…very pleasant by his work…I would recommend his work 110%.",
      date: "July 2021",
      link: "https://goo.gl/maps/rP8Dmz1PCKg7rZMX8"
    },
  ]

  const commentsPerSlide = 4;

  const [slides] = useState(getSlides(comments))
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  function getSlides(comments) {
    const slides = [];
    for (let i=0; i<Math.ceil(comments.length/commentsPerSlide); i++) {
      slides.push({
        index: i,
        open: i === 0,
        comments: comments.slice(commentsPerSlide * i, commentsPerSlide * i + commentsPerSlide),
      })
    }
    return slides;
  }

  function goToSlide(index) {
    slides[activeSlideIndex].open = false;
    slides[index].open = true;
    setActiveSlideIndex(index);
  }

  function prev() {
    slides[activeSlideIndex].open = false;
    slides[activeSlideIndex-1].open = true;
    setActiveSlideIndex(activeSlideIndex-1);
  }

  function next() {
    slides[activeSlideIndex].open = false;
    slides[activeSlideIndex+1].open = true;
    setActiveSlideIndex(activeSlideIndex+1);
  }

  function loadMore() {
    slides[activeSlideIndex+1].open = true;
    setActiveSlideIndex(activeSlideIndex+1);
  }

  return (
    <div className="carousel flex flex-col gap-5">
      <div className="slides flex flex-col gap-10">
        {slides.map((slide, sindex) => (
          <div className={`slide relative lg:absolute ${slide.open ? 'open' : ''}`} key={sindex}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:flex flex-row gap-10">
              {slide.comments.map((comment, cindex) => (
                <div className="comment flex flex-1 flex-col gap-3 bg-white p-5" key={cindex}>
                  <div className="stars flex flex-row">
                    {[...Array(comment.stars)].map((i) => (
                      <StarSvg />
                    ))}
                  </div>
                  <div className="author flex flex-row gap-5">
                    <img src={comment.image} alt="Author" className="self-center"/>
                    <div className="flex flex-col">
                      <p className="name">{comment.author}</p>
                      <p className="date">{comment.date}</p>
                    </div>
                  </div>
                  <p className="content">{comment.content}</p>
                  {!!comment.link && (
                    <div className="link flex flex-row-reverse">
                      <div className="flex flex-row items-center gap-2">
                        <a href={comment.link} target="_blank" rel="noopener noreferrer">Read on Google</a>
                        <ArrowRightDarkSvg/>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="arrows hidden lg:block">
          <button type="button" className={`flex items-center justify-center left ${activeSlideIndex === 0 ? 'hidden' : ''}`} aria-label="Left" onClick={prev}><ArrowSvg/></button>
          <button type="button" className={`flex items-center justify-center right ${activeSlideIndex === slides.length - 1 ? 'hidden' : ''}`} aria-label="Right" onClick={next}><ArrowSvg/></button>
        </div>
      </div>
      <div className="controls hidden lg:flex justify-center">
        <div className="flex flex-row gap-10">
          {[...Array(slides.length)].map((slide, index) => (
            <button aria-label={`Step ${index + 1}`} type="button" className={`control ${activeSlideIndex === index ? 'active' : ''}`} key={index} onClick={() => goToSlide(index)} />
          ))}
        </div>
      </div>
      {activeSlideIndex !== slides.length - 1 && (
        <button type="button" className="lg:hidden gdb-btn transparent flex self-center" onClick={loadMore}>Load more</button>
      )}
    </div>
  )
}

export default CarouselComponent
